<template>
  <div class="password-forgot">
    <form class="password-forgot__form" action="" @submit.prevent="passwordForgot">
      <div v-if="!passwordShow" class="password-forgot__item">
        <label for="">Email</label>
        <b-input
          v-model="email"
          type="email"
          class="password-forgot__input main-input"
          placeholder="Введите свой e-mail"
        />
      </div>
      <div v-if="passwordShow" class="password-forgot__item">
        <label for="">Код</label>
        <b-input
          v-model="code"
          type="text"
          maxlength="4"
          class="password-forgot__input-password main-input"
          placeholder="Введите код"
        />
      </div>
      <div v-if="passwordShow" class="password-forgot__item">
        <label for="">Пароль</label>
        <b-input
          v-model="password"
          :type="iconShow ? 'password' : 'text'"
          minlength="3"
          class="password-forgot__input-password main-input"
          placeholder="Придумайте пароль"
          required
        />
        <a>
          <b-icon
            class="password-forgot__input-icon"
            :icon="iconShow ? 'eye' : 'eye-slash'"
            @click="iconShow = !iconShow"
          />
        </a>
      </div>
      <div v-if="passwordShow" class="password-forgot__item">
        <b-input
          v-model="passwordRepeat"
          :type="iconShow ? 'password' : 'text'"
          minlength="3"
          class="password-forgot__input-password main-input"
          placeholder="Повторите пароль"
          required
        />
      </div>
      <button type="submit" class="password-forgot__btn main-btn">
        Продолжить регистрацию
        <b-icon class="password-forgot__btn-icon" icon="arrow-right" />
      </button>
    </form>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      passwordShow: false,
      codeShow: false,
      iconShow: true,
      email: null,
      code: null,
      codeLength: null,
      password: null,
      passwordRepeat: null,
    };
  },
  methods: {
    passwordForgot() {
      if (!this.passwordShow) {
        this.confirmationEmail();
      } else {
        this.editPassword();
      }
    },
    async confirmationEmail() {
      const formData = new FormData();
      formData.append("login", this.email);
      await this.$api
        .post("/web/password-reset", formData)
        .then(() => {
          this.passwordShow = true;
        })
        .catch((e) => {
          this.$toast.error(e.response.data?.message);
        });
    },
    async editPassword() {
      if (this.password !== this.passwordRepeat) {
        this.$toast.warning("Пароль не подходит");
        return;
      }
      const formData = new FormData();
      formData.append("code", this.code);
      formData.append("password", this.password);
      await this.$api
        .post("/web/password-reset-code", formData)
        .then((response) => {
          this.passwordShow = false;
          Cookies.set("employee_token", response.data.data.token);
          this.$router.push("/");
        })
        .catch((e) => {
          this.$toast.error(e.response.data?.message);
        });
    },
  },
};
</script>

<style scoped>
.password-forgot {
  margin: 40px 0 30px;
  width: 100%;
}
.password-forgot__item {
  width: 100%;
  position: relative;
}
.password-forgot__input-password {
  margin: 5px 0 10px;
}
.password-forgot__btn {
  position: relative;
  margin: 20px 0 0 0;
}
.password-forgot__btn-icon,
.password-forgot__input-icon {
  position: absolute;
  right: 20px;
}
.password-forgot__input-icon {
  top: 55%;
}
</style>
